import { useCallback, useEffect, useMemo, useRef, useState } from "react";
// import background from "./assets/elements/bg.jpg";
// import background from "./assets/elements/min/bg.jpg";
import bg1 from "./assets/elements/min/bg-splitted/image_part_001.jpg";
import bg2 from "./assets/elements/min/bg-splitted/image_part_002.jpg";
import bg3 from "./assets/elements/min/bg-splitted/image_part_003.jpg";
import bg4 from "./assets/elements/min/bg-splitted/image_part_004.jpg";
import bg5 from "./assets/elements/min/bg-splitted/image_part_005.jpg";
import bg6 from "./assets/elements/min/bg-splitted/image_part_006.jpg";
import bg7 from "./assets/elements/min/bg-splitted/image_part_007.jpg";

// import background from "./assets/references/Referencia_patas.jpg";
// import background from "./assets/references/Ref imagem completa/ref.jpg";
import IterableElement, {
  InteractionType,
} from "./components/iterable-element";
import Scene, { SceneRefObject } from "./components/scene";
import cx from "classnames";

import cozinha_armario_inf_direito_img from "./assets/elements/min/cozinha/cozinha_armario_inf_direito.png";
import cozinha_gaveta_meio_img from "./assets/elements/min/cozinha/cozinha_gaveta_meio.png";
import cozinha_armario_inf_esquerdo_img from "./assets/elements/min/cozinha/cozinha_armario_inf_esquerdo.png";
import cozinha_geladeira_inferior_img from "./assets/elements/min/cozinha/cozinha_geladeira_inferior.png";
import cozinha_armario_sup_img from "./assets/elements/min/cozinha/cozinha_armario_sup.png";
import cozinha_geladeira_superior_img from "./assets/elements/min/cozinha/cozinha_geladeira_superior.png";
import cozinha_fogao_img from "./assets/elements/min/cozinha/cozinha_fogao.png";
import cozinha_liquidificador_img from "./assets/elements/min/cozinha/cozinha_liquidificador.png";
import cozinha_gaveta_baixo_img from "./assets/elements/min/cozinha/cozinha_gaveta_baixo.png";
import cozinha_microondas_img from "./assets/elements/min/cozinha/cozinha_microondas.png";
import cozinha_gaveta_cima_img from "./assets/elements/min/cozinha/cozinha_gaveta_cima.png";
import cozinha_panela_img from "./assets/elements/min/cozinha/cozinha_panela.png";
import jardim_arbusto_img from "./assets/elements/min/jardim/jardim_arbusto.png";
import jardim_baldinho_b_img from "./assets/elements/min/jardim/jardim_baldinho_b.png";
import jardim_pa_img from "./assets/elements/min/jardim/jardim_pa.png";
import jardim_baldinho_a_img from "./assets/elements/min/jardim/jardim_baldinho_a.png";
import jardim_carrinho_img from "./assets/elements/min/jardim/jardim_carrinho.png";
import sala_almofadas_img from "./assets/elements/min/sala/sala_almofadas.png";
import sala_chaleira_img from "./assets/elements/min/sala/sala_chaleira.png";
import sala_livros_img from "./assets/elements/min/sala/sala_livros.png";
import sala_relogio_img from "./assets/elements/min/sala/sala_relogio.png";
import pegadachao_img from "./assets/elements/pegadachao.png";
import pegadaparede_img from "./assets/elements/pegadaparede.png";
import instagram_img from "./assets/elements/instagram-logo.svg";
import wpp_img from "./assets/elements/wpp.png";
import insta_img from "./assets/elements/intagram.png";

// import caca_ao_tesouro_img from "./assets/elements/CacaAoTesouro.png";
// import caca_ao_tesouro_img from "./assets/elements/logo_coelho.png";
import caca_ao_tesouro_img from "./assets/elements/CacaAoTesouro_logo2.png";
import timer_img from "./assets/elements/Timer.png";
import chocker_img from "./assets/elements/chokerhud.png";
import Button from "./components/button";
// import takeScreenshot from "./utils/takeScreenshot";
import isMobileOrTablet from "./utils/isMobileOrTablet";
// import waitForSeconds from "./utils/waitForSeconds";
import getFormattedTime from "./utils/getFormattedTime";
import Pegadas from "./components/pegadas";
import SoundControl from "./components/sound-control";
import Pyro from "./components/pyro";
import CounterParticle from "./components/counter-particle";
import classNames from "classnames";
import takeScreenshot from "./utils/takeScreenshot";

const time = 3 * 60 * 1000;
// const time = 10 * 1000;
const hurryUpSeconds = 30;

const isMobile = isMobileOrTablet();

const TREASURES_COUNT = 7;

type IterableId =
  | "cozinha_armario_inf_direito"
  | "cozinha_gaveta_meio"
  | "cozinha_armario_inf_esquerdo"
  | "cozinha_geladeira_inferior"
  | "cozinha_armario_sup"
  | "cozinha_geladeira_superior"
  | "cozinha_fogao"
  | "cozinha_liquidificador"
  | "cozinha_gaveta_baixo"
  | "cozinha_microondas"
  | "cozinha_gaveta_cima"
  | "cozinha_panela"
  | "jardim_arbusto"
  | "jardim_pa"
  | "jardim_baldinho_a"
  | "jardim_carrinho"
  | "sala_almofadas"
  | "sala_chaleira"
  | "sala_livros"
  | "sala_relogio";

type IterableElementProps = {
  image: string;
  type?: InteractionType;
  analytics: string;
  swapImage?: {
    image: string;
    id: string;
  };
  hasTreasure?: boolean;
};

const play = (id: string) => {
  const audio = document.getElementById(id) as any;

  if (audio) {
    if (!audio.paused) {
      audio.pause();
      audio.currentTime = 0;
    }

    audio.play();
  }
};

const stop = (id: string) => {
  const audio = document.getElementById(id) as any;

  if (audio) {
    audio.pause();
    audio.currentTime = 0;
  }
};

function App() {
  const [state, setState] = useState<"start" | "instructions" | "game" | "end">(
    "start"
  );
  const [isOpen, setIsOpen] = useState<
    Partial<{ [id in IterableId]: boolean }>
  >({});
  const [hideStart, setHideStart] = useState<boolean>(false);
  const [remainingTime, setRemainingTime] = useState<number>(time);
  const timerRef = useRef<ReturnType<typeof setInterval>>();
  const [treasureFound, setTreasureFound] = useState<
    Partial<{ [id in IterableId]: boolean }>
  >({});
  // const [log, setLog] = useState<string[]>([]);
  const [screenshotData, setScreenshotData] = useState<string | null>(null);
  const filesArray = useRef<File[]>([]);
  const [showingResults, setShowingResults] = useState<boolean>(true);
  // const [savingScreenshot, setSavingScreenshot] = useState<boolean>(false);
  const sceneRef = useRef<SceneRefObject>(null);
  const iterableElements = useRef<{ [id in IterableId]: IterableElementProps }>(
    {
      cozinha_armario_inf_direito: {
        image: cozinha_armario_inf_direito_img,
        type: "show",
        analytics: "Armário Direito",
      },
      cozinha_fogao: {
        image: cozinha_fogao_img,
        type: "show",
        analytics: "Fogão",
      },
      cozinha_gaveta_baixo: {
        image: cozinha_gaveta_baixo_img,
        type: "show",
        analytics: "Gaveta do Inferior",
      },
      cozinha_gaveta_meio: {
        image: cozinha_gaveta_meio_img,
        type: "show",
        // hasTreasure: true,
        analytics: "Gaveta do Meio",
      },
      cozinha_gaveta_cima: {
        image: cozinha_gaveta_cima_img,
        type: "show",
        analytics: "Gaveta do Superior",
      },
      cozinha_armario_inf_esquerdo: {
        image: cozinha_armario_inf_esquerdo_img,
        type: "show",
        analytics: "Armário Esquerdo",
      },
      cozinha_geladeira_inferior: {
        image: cozinha_geladeira_inferior_img,
        type: "show",
        analytics: "Geladeira Porta Inferior",
      },
      cozinha_armario_sup: {
        image: cozinha_armario_sup_img,
        type: "show",
        analytics: "Armário Superior",
      },
      cozinha_geladeira_superior: {
        image: cozinha_geladeira_superior_img,
        type: "show",
        // hasTreasure: true,
        analytics: "Geladeira Porta Superior",
      },
      cozinha_liquidificador: {
        image: cozinha_liquidificador_img,
        type: "move",
        analytics: "Liquidificador",
      },
      cozinha_microondas: {
        image: cozinha_microondas_img,
        type: "show",
        // hasTreasure: true,
        analytics: "Microondas",
      },
      cozinha_panela: {
        image: cozinha_panela_img,
        type: "move",
        analytics: "Panela",
      },
      jardim_arbusto: {
        image: jardim_arbusto_img,
        // hasTreasure: true,
        analytics: "Arbusto",
      },
      jardim_pa: { image: jardim_pa_img, analytics: "Pazinha" },
      jardim_baldinho_a: {
        image: jardim_baldinho_a_img,
        type: "swap",
        swapImage: {
          id: "jardim_baldinho_b",
          image: jardim_baldinho_b_img,
        },
        // hasTreasure: true,
        analytics: "Baldinho ",
      },
      jardim_carrinho: {
        image: jardim_carrinho_img,
        type: "move",
        analytics: "Carrinho",
      },
      sala_almofadas: {
        image: sala_almofadas_img,
        // hasTreasure: true,
        analytics: "Almofadas",
      },
      sala_chaleira: {
        image: sala_chaleira_img,
        type: "move",
        analytics: "Chaleira",
      },
      sala_livros: {
        image: sala_livros_img,
        // hasTreasure: true,
        analytics: "Livros",
      },
      sala_relogio: {
        image: sala_relogio_img,
        type: "move",
        analytics: "Relógio",
      },
    }
  );

  const generateElementsWithTreasure = () => {
    const treasureIds = Object.keys(iterableElements.current).sort(
      () => Math.random() - 0.5
    ) as IterableId[];

    for (let i = 0; i < treasureIds.length; i++) {
      iterableElements.current[treasureIds[i]].hasTreasure =
        i < TREASURES_COUNT;

      // if (i < TREASURES_COUNT) {
      //   console.log(treasureIds[i]);
      // }
    }
  };
  // const soundtrackRef = useRef<AudioBufferSourceNode | null>();

  const startSoundtrack = async () => {
    // soundtrackRef.current = await playAudio("./sound/TrilhaSonora.mp3", true);
    // play("./sound/TrilhaSonora.mp3", true);
    play("TrilhaSonora");
  };

  const startGame = () => {
    generateElementsWithTreasure();
    setState("game");
    setRemainingTime(time);
    setTreasureFound({});
    setIsOpen({});
    startSoundtrack();
    sceneRef.current?.scrollBack();
    setShowingResults(true);

    setTimeout(() => {
      setHideStart(true);
    }, 300);
    timerRef.current = setInterval(() => {
      setRemainingTime((value) => value - 1000);
    }, 1000);
  };

  const totalFound = useMemo(
    () =>
      Object.values(treasureFound).reduce(
        (sum, value) => sum + (value ? 1 : 0),
        0
      ),
    [treasureFound]
  );

  const victory = useMemo(() => totalFound >= TREASURES_COUNT, [totalFound]);

  // const shouldBeClosed = (id: IterableId, found: typeof treasureFound) => {
  //   if (
  //     (id === "cozinha_gaveta_baixo" || id === "cozinha_gaveta_cima") &&
  //     Boolean(found["cozinha_gaveta_meio"])
  //   ) {
  //     return true;
  //   }

  //   return false;
  // };

  // const pushLog = useCallback(
  //   (message: string) => {
  //     setLog((log) => [...log, message]);
  //   },
  //   [setLog]
  // );

  const saveScreenshot = useCallback(async () => {
    setShowingResults(true);
    // setSavingScreenshot(true);
    // pushLog("share started");
    // await waitForSeconds(0.3);

    setTimeout(() => {
      setShowingResults(false);
    }, 4000);

    const screenshot = await takeScreenshot();

    if (isMobile) {
      // pushLog("screenshot taken");
      const blob = await (await fetch(screenshot)).blob();
      // pushLog("blob created");
      const files: File[] = [
        new File([blob], "screenshot.png", {
          type: blob.type,
          lastModified: new Date().getTime(),
        }),
      ];
      filesArray.current = files;
    } else {
      setScreenshotData(screenshot);
    }

    // setSavingScreenshot(false);
    // }, [pushLog, setShowingResults]);
  }, [setShowingResults]);

  useEffect(() => {
    if (victory) {
      inc_event("Finalização", "Encontrou todos");
      setTimeout(() => {
        setState("end");
        // playAudio("./sound/FimJogo.mp3");
        play("FimJogo");
        // soundtrackRef.current?.stop();
        stop("TrilhaSonora");
      }, 2000);
    }
  }, [treasureFound, victory]);

  useEffect(() => {
    if (Math.floor(remainingTime / 1000) === hurryUpSeconds) {
      // playAudio("./sound/TempoAcabando.mp3");
      play("TempoAcabando");
    }

    if (remainingTime <= 0 && state === "game") {
      inc_event("Finalização", "Não encontrou todos");
      setState("end");
      // playAudio("./sound/TenteNovamente.mp3");
      play("TenteNovamente");
      // soundtrackRef.current?.stop();
      stop("TrilhaSonora");
    }
  }, [remainingTime, setState, state]);

  useEffect(() => {
    if (state === "end") {
      saveScreenshot();
    }
  }, [state, saveScreenshot]);

  useEffect(() => {
    if (state === "end" && timerRef.current) {
      clearInterval(timerRef.current);
    }
  }, [state, timerRef]);

  // eslint-disable-next-line
  function onShare() {
    if (isMobile) {
      const shareData = {
        files: filesArray.current,
      };

      // pushLog("navigator.share called");
      try {
        navigator.share(shareData);
      } catch (error) {
        // alert(`Share error: ${error}`);
      }
    } else {
      const link = document.createElement("a");
      link.href = screenshotData || "";
      link.download = "screenshot.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  // const staticScreenshotRef = useRef<File[]>([]);

  // const saveStaticScreenshot = async () => {
  //   const response = await fetch("screenshot.jpeg");
  //   const blob = await response.blob();
  //   const filesArray: File[] = [
  //     new File([blob], "screenshot.jpeg", {
  //       type: "image/jpeg",
  //       lastModified: new Date().getTime(),
  //     }),
  //   ];
  //   staticScreenshotRef.current = filesArray;
  // };

  // useEffect(() => {
  //   saveStaticScreenshot();
  // }, []);

  // async function onShareStatic() {
  //   console.log("onShareStatic");
  //   const shareData = {
  //     files: saveScreenshot.current,
  //   };

  //   navigator.share(shareData as any).then(() => {
  //     console.log("Shared successfully");
  //   });
  // }

  function onShareLink() {
    const message = "Ajude o coelhinho a encontrar os biscoitos Choker";
    try {
      if (isMobile) {
        navigator.share({
          title: "Caca ao Choker",
          text: message,
          url: window.location.href,
        });
      } else {
        const link = document.createElement("a");
        const params = new URLSearchParams({
          text: `${message}! Jogue também: ${window.location.href}`,
        });
        link.href = `https://wa.me/?${params.toString()}`;
        link.target = "_blank";
        document.body.appendChild(link);
        console.log(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      // alert(`Share error: ${error}`);
    }
  }

  return (
    <div className="App">
      <SoundControl className="soundButton" />
      {/* <span id="log">{log.join("\n")}</span> */}
      <div
        id="start_popover"
        className={cx("popover", {
          noBackground: !(state === "start" || state === "instructions"),
          hidden: hideStart,
        })}
      >
        <Pegadas className={cx("pegadas", hideStart && "hidden")} />
        <Pegadas
          className={cx("pegadas pegadas-reverse", hideStart && "hidden")}
        />
        <div id="start" className={cx(state !== "start" && "hidden")}>
          <img alt="" src={caca_ao_tesouro_img} className="cacaAoTesouro" />
          <Button
            label="jogar"
            className="button"
            onClick={() => {
              inc_event("Botões", "Jogar");
              setState("instructions");
            }}
          />
          <div className="logo animated" />
        </div>
        <div
          id="instructions"
          className={cx({
            idle: state === "start",
            hidden: state !== "start" && state !== "instructions",
          })}
        >
          <img alt="" src={caca_ao_tesouro_img} className="cacaAoTesouro" />
          <span className="instructions-text">
            O coelhinho da Páscoa esqueceu
            <br />
            onde escondeu os biscoitos Choker.
            <br />
            Vamos procurar juntos?
            <br />
            <br />
            1 - Clique nos elementos do cenário
            <br />
            para descobrir onde está o Choker;
            <br />
            <br />
            2 - Arraste a tela para trocar de cenário;
            <br />
            <br />
            3 - Quem encontrar os 7 biscoitos Choker
            <br />
            em um menor tempo é o vencedor!
          </span>
          <Button
            label="começar"
            className="button"
            onClick={() => {
              inc_event("Botões", "Começar");
              startGame();
            }}
          />
          <div className="logo animated" />
        </div>
      </div>
      <div
        id="end"
        className={cx("popover", {
          hidden: state !== "end",
        })}
      >
        <Pegadas className={cx("pegadas", hideStart && "hidden")} />
        <Pegadas
          className={cx("pegadas pegadas-reverse", hideStart && "hidden")}
        />
        <div
          id="screenshot"
          className={cx({
            hidden: !victory || !showingResults,
          })}
        >
          <img alt="" src={caca_ao_tesouro_img} className="cacaAoTesouro" />
          {/* {savingScreenshot ? ( */}
          <span className="end-text">
            Ajude o coelhinho a encontrar
            <br />
            os biscoitos Choker.
            <br />
            <br />
            Eu encontrei todos os biscoitos em:
            <br />
            <strong>{getFormattedTime((time - remainingTime) / 1000)}</strong>
            <br />
            Agora é a sua vez!
          </span>
          {/* ) : (
            <span className="end-text">
              <strong>Parabéns!</strong>
              <br />
              Você encontrou todos
              <br />
              os biscoitos Chocker em:
              <br />
              <strong>{getFormattedTime((time - remainingTime) / 1000)}</strong>
              <br />
              Agora é hora de desafiar seus amigos
              <br />e ver quem é o mais rápido!
            </span>
          )}
          {savingScreenshot && ( */}
          <div className="instagram">
            <img alt="" src={instagram_img} />
            <span>
              Acesse @vilmaalimentos para
              <br />
              jogar também (link na Bio)
            </span>
          </div>
          {/* )} */}
          <div className="logo" />
        </div>
        <div
          id="end_inner"
          className={cx({
            idle: !(!victory || !showingResults),
          })}
        >
          <img alt="" src={caca_ao_tesouro_img} className="cacaAoTesouro" />
          <span className={classNames("end-text", victory && "disappear")}>
            Poxa, não foi dessa vez!
            <br />
            Mas não se preocupe, é só tentar de novo.
          </span>
          <span className={classNames("end-text", !victory && "disappear")}>
            Convide seus amigos para jogar!
          </span>
          <div className={classNames("social-btns", !victory && "disappear")}>
            <button
              className="social-btn"
              onClick={() => {
                inc_event("Botões", "Compartilhar");
                onShare();
              }}
            >
              <img src={insta_img} alt="instagram" />
            </button>
            <button
              className="social-btn"
              onClick={() => {
                inc_event("Botões", "Compartilhar");
                onShareLink();
              }}
            >
              <img src={wpp_img} alt="whatsapp" />
            </button>
          </div>

          <Button
            label="jogar novamente"
            className={classNames("button", !victory && "disappear")}
            onClick={() => {
              inc_event("Botões", "Jogar novamente");
              startGame();
            }}
          />
          <Button
            label="tentar de novo"
            className={classNames("button", victory && "disappear")}
            onClick={startGame}
          />
          <div className="logo animated" />
        </div>
      </div>
      <div className={cx("hud", state !== "game" && "hidden")} id="timer">
        <img alt="" src={timer_img} />
        <span>{getFormattedTime(remainingTime / 1000)}</span>
      </div>
      <div className={cx("hud", state !== "game" && "hidden")} id="choker">
        <img alt="" src={chocker_img} />
        <span>{TREASURES_COUNT - totalFound}</span>
      </div>
      <Scene disabled={state !== "game"} ref={sceneRef}>
        {/* <img src={background} alt="" id="background" /> */}
        <div id="background">
          <img src={bg1} alt="" />
          <img src={bg2} alt="" />
          <img src={bg3} alt="" />
          <img src={bg4} alt="" />
          <img src={bg5} alt="" />
          <img src={bg6} alt="" />
          <img src={bg7} alt="" />
        </div>
        <img
          className="pegada pegada_chao"
          src={pegadachao_img}
          alt=""
          id="p1"
        />
        <img
          className="pegada pegada_chao"
          src={pegadachao_img}
          alt=""
          id="p2"
        />
        <img
          className="pegada pegada_chao"
          src={pegadachao_img}
          alt=""
          id="p3"
        />
        <img
          className="pegada pegada_chao"
          src={pegadachao_img}
          alt=""
          id="p4"
        />
        <img
          className={cx(
            "pegada pegada_parede",
            isOpen["cozinha_fogao"] && "hidden"
          )}
          src={pegadaparede_img}
          alt=""
          id="p5"
        />
        <img
          className={cx(
            "pegada pegada_parede",
            isOpen["cozinha_fogao"] && "hidden"
          )}
          src={pegadaparede_img}
          alt=""
          id="p6"
        />
        <img
          className={cx(
            "pegada pegada_parede",
            isOpen["cozinha_fogao"] && "hidden"
          )}
          src={pegadaparede_img}
          alt=""
          id="p7"
        />
        <img
          className="pegada pegada_chao"
          src={pegadachao_img}
          alt=""
          id="p8"
        />
        <img
          className="pegada pegada_chao"
          src={pegadachao_img}
          alt=""
          id="p9"
        />
        <img
          className="pegada pegada_chao"
          src={pegadachao_img}
          alt=""
          id="p10"
        />
        <img
          className="pegada pegada_chao"
          src={pegadachao_img}
          alt=""
          id="p11"
        />
        <img
          className={cx(
            "pegada pegada_parede",
            isOpen["cozinha_geladeira_inferior"] && "hidden"
          )}
          src={pegadaparede_img}
          alt=""
          id="p12"
        />
        <img
          className={cx(
            "pegada pegada_parede",
            isOpen["cozinha_geladeira_inferior"] && "hidden"
          )}
          src={pegadaparede_img}
          alt=""
          id="p13"
        />
        <img
          className={cx(
            "pegada pegada_parede",
            isOpen["cozinha_geladeira_inferior"] && "hidden"
          )}
          src={pegadaparede_img}
          alt=""
          id="p14"
        />
        <img
          className={cx(
            "pegada pegada_parede",
            isOpen["cozinha_geladeira_inferior"] && "hidden"
          )}
          src={pegadaparede_img}
          alt=""
          id="p15"
        />
        <img
          className={cx(
            "pegada pegada_parede",
            treasureFound["cozinha_geladeira_superior"] && "hidden"
          )}
          src={pegadaparede_img}
          alt=""
          id="p16"
        />
        <img
          className={cx(
            "pegada pegada_parede",
            treasureFound["cozinha_geladeira_superior"] && "hidden"
          )}
          src={pegadaparede_img}
          alt=""
          id="p17"
        />
        <img
          className={cx(
            "pegada pegada_parede",
            treasureFound["cozinha_geladeira_superior"] && "hidden"
          )}
          src={pegadaparede_img}
          alt=""
          id="p18"
        />
        <img
          className={cx(
            "pegada pegada_parede",
            treasureFound["cozinha_geladeira_superior"] && "hidden"
          )}
          src={pegadaparede_img}
          alt=""
          id="p19"
        />
        <img
          className="pegada pegada_chao"
          src={pegadachao_img}
          alt=""
          id="p20"
        />
        <img
          className="pegada pegada_chao"
          src={pegadachao_img}
          alt=""
          id="p21"
        />
        <img
          className="pegada pegada_chao"
          src={pegadachao_img}
          alt=""
          id="p22"
        />
        <img
          className="pegada pegada_chao"
          src={pegadachao_img}
          alt=""
          id="p23"
        />
        <img
          className="pegada pegada_parede"
          src={pegadaparede_img}
          alt=""
          id="p24"
        />
        <img
          className="pegada pegada_parede"
          src={pegadaparede_img}
          alt=""
          id="p25"
        />
        <img
          className="pegada pegada_parede"
          src={pegadaparede_img}
          alt=""
          id="p26"
        />
        <img
          className="pegada pegada_chao"
          src={pegadachao_img}
          alt=""
          id="p27"
        />
        <img
          className="pegada pegada_chao"
          src={pegadachao_img}
          alt=""
          id="p28"
        />
        <img
          className="pegada pegada_chao"
          src={pegadachao_img}
          alt=""
          id="p29"
        />
        <img
          className="pegada pegada_chao"
          src={pegadachao_img}
          alt=""
          id="p30"
        />
        <img
          className="pegada pegada_chao"
          src={pegadachao_img}
          alt=""
          id="p31"
        />
        <img
          className="pegada pegada_chao"
          src={pegadachao_img}
          alt=""
          id="p32"
        />
        <img
          className="pegada pegada_chao"
          src={pegadachao_img}
          alt=""
          id="p33"
        />
        <img
          className="pegada pegada_chao"
          src={pegadachao_img}
          alt=""
          id="p34"
        />
        <img
          className="pegada pegada_chao"
          src={pegadachao_img}
          alt=""
          id="p35"
        />
        <img
          className="pegada pegada_chao"
          src={pegadachao_img}
          alt=""
          id="p36"
        />
        <img
          className="pegada pegada_chao"
          src={pegadachao_img}
          alt=""
          id="p37"
        />
        <img
          className="pegada pegada_chao"
          src={pegadachao_img}
          alt=""
          id="p38"
        />
        <img
          className="pegada pegada_chao"
          src={pegadachao_img}
          alt=""
          id="p39"
        />
        <img
          className="pegada pegada_chao"
          src={pegadachao_img}
          alt=""
          id="p40"
        />
        <img
          className="pegada pegada_chao"
          src={pegadachao_img}
          alt=""
          id="p41"
        />
        <img
          className="pegada pegada_chao"
          src={pegadachao_img}
          alt=""
          id="p42"
        />
        <img
          className="pegada pegada_chao"
          src={pegadachao_img}
          alt=""
          id="p43"
        />
        <img
          className="pegada pegada_chao"
          src={pegadachao_img}
          alt=""
          id="p44"
        />
        <img
          className="pegada pegada_chao"
          src={pegadachao_img}
          alt=""
          id="p45"
        />
        <img
          className="pegada pegada_chao"
          src={pegadachao_img}
          alt=""
          id="p46"
        />
        <img
          className="pegada pegada_chao"
          src={pegadachao_img}
          alt=""
          id="p47"
        />
        <img
          className="pegada pegada_chao"
          src={pegadachao_img}
          alt=""
          id="p48"
        />
        <img
          className="pegada pegada_chao"
          src={pegadachao_img}
          alt=""
          id="p49"
        />
        <img
          className="pegada pegada_chao"
          src={pegadachao_img}
          alt=""
          id="p50"
        />
        {Object.entries(iterableElements.current).map(
          ([id, { image, type, swapImage, hasTreasure, analytics }]) => (
            <IterableElement
              key={id}
              id={id}
              type={type}
              hasTreasure={hasTreasure && !treasureFound[id as IterableId]}
              isOpen={
                // !shouldBeClosed(id as IterableId, treasureFound) &&
                isOpen[id as IterableId]
              }
              onClick={(id) => {
                inc_event("Objetos", analytics);
                setIsOpen({ [id]: !isOpen[id as IterableId] });
                if (hasTreasure) {
                  if (!treasureFound[id as IterableId]) {
                    // playAudio("./sound/EncontrouChocker.mp3");
                    play("EncontrouChocker");
                  }

                  setTreasureFound((values) => ({
                    ...values,
                    [id]: true,
                  }));
                } else {
                  // playAudio("./sound/NaoEncontrouChocker.mp3");
                  play("NaoEncontrouChocker");
                }
              }}
            >
              <img alt="" src={image} />
              {swapImage && (
                <img
                  alt=""
                  id={swapImage.id}
                  src={swapImage.image}
                  className="swapImage"
                />
              )}
              {hasTreasure && treasureFound[id as IterableId] && (
                <>
                  <Pyro />
                  <CounterParticle
                    value={totalFound}
                    className={classNames(totalFound === 7 && "disappear")}
                  />
                </>
              )}
            </IterableElement>
          )
        )}
      </Scene>
      <audio id="Botao" src="sound/Botao.mp3"></audio>
      <audio id="EncontrouChocker" src="sound/EncontrouChocker.mp3"></audio>
      <audio id="FimJogo" src="sound/FimJogo.mp3"></audio>
      <audio
        id="NaoEncontrouChocker"
        src="sound/NaoEncontrouChocker.mp3"
      ></audio>
      <audio id="TempoAcabando" src="sound/TempoAcabando.mp3"></audio>
      <audio id="TenteNovamente" src="sound/TenteNovamente.mp3"></audio>
      <audio id="TrilhaSonora" src="sound/TrilhaSonora.mp3" loop></audio>
    </div>
  );
}

export default App;
