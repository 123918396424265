import choker_img from "../assets/elements/chocker.png";

const itemFoundAnimation = (x: number, y: number): void => {
  const element = document.createElement("img");

  element.src = choker_img;
  element.classList.add("element-found-animated");
  element.style.right = `${window.screen.width - x}px`;
  element.style.top = `${y}px`;

  document.getElementById("root")?.appendChild(element);

  setTimeout(() => {
    element.remove();

    const pyro = document.createElement("pyro");
    pyro.classList.add("pyro");

    const before = document.createElement("before");
    before.classList.add("before");
    const after = document.createElement("after");
    after.classList.add("after");
    pyro.appendChild(before);
    pyro.appendChild(after);

    pyro.style.right = "18svh";
    pyro.style.top = "10svh";
    pyro.style.left = "unset";
    pyro.style.zIndex = "9";

    document.getElementById("root")?.appendChild(pyro);

    setTimeout(() => {
      pyro.remove();
    }, 5000);
  }, 1000);
};

export default itemFoundAnimation;
