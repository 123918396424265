import cx from "classnames";
import pegadaparede_img from "../../assets/elements/pegadaparede.png";
import style from "./pegadas.module.css";

type Props = {
  className?: string;
};

const Pegadas = ({ className }: Props) => {
  return (
    <div className={cx(style.pegadas, className)}>
      <img className="pegada pegada_parede" src={pegadaparede_img} alt="" />
      <img className="pegada pegada_parede" src={pegadaparede_img} alt="" />
      <img className="pegada pegada_parede" src={pegadaparede_img} alt="" />
      <img className="pegada pegada_parede" src={pegadaparede_img} alt="" />
      <img className="pegada pegada_parede" src={pegadaparede_img} alt="" />
      <img className="pegada pegada_parede" src={pegadaparede_img} alt="" />
      <img className="pegada pegada_parede" src={pegadaparede_img} alt="" />
    </div>
  );
};

export default Pegadas;
