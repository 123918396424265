import cx from "classnames";
import { MouseEventHandler, PropsWithChildren, useRef } from "react";
import style from "./iterable-element.module.css";
import itemFoundAnimation from "../../utils/itemFoundAnimation";

export type InteractionType = "hide" | "show" | "move" | "swap";

type Props = PropsWithChildren<{
  id: string;
  isOpen?: boolean;
  onClick?: (id: string) => void;
  type?: InteractionType;
  hasTreasure?: boolean;
}>;

const IterableElement = ({
  id,
  isOpen = false,
  onClick,
  children,
  type = "hide",
  hasTreasure = false,
}: Props) => {
  const ref = useRef<HTMLButtonElement>(null);

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (hasTreasure) {
      itemFoundAnimation(e.pageX, e.pageY);
    }

    onClick?.(id);
  };

  return (
    <button
      type="button"
      onClick={handleClick}
      id={id}
      ref={ref}
      className={cx(style.iterableElement, {
        [`${style.iterableElementOpen} active`]: isOpen,
        [style.iterableElementHide]: type === "hide",
        [style.iterableElementShow]: type === "show",
        [style.iterableElementMove]: type === "move",
        [style.iterableElementSwap]: type === "swap",
      })}
    >
      {children}
    </button>
  );
};

export default IterableElement;
